<template>
  <div class="Membership">
    <div class="Membership-header">
      <p class="header-one">
        <van-icon name="arrow-left" size="24px" />
      </p>
      <p class="header-two">会员升级</p>
      <p class="header-tress">
        <van-icon name="ellipsis" size="24px" />
      </p>
    </div>
    <div>
      <van-swipe>
        <van-swipe-item v-for="(item,index) in getMemberlist" :key="index">
          <div class="Membership-center">
            <p class="center-one"></p>
            <div :class="item.confirmReceipt == 1?'center-two':item.confirmReceipt == 2?'center-two1':item.confirmReceipt == 3?'center-two2':'' ">
              <p class="center-two-first">
                <span>{{item.memberName}}</span>
                <span>未解锁</span>
              </p>
              <div class="cneter-general">
                <p class="center-two-last">
                  <span>邀请新用户</span>
                  <span>3/10</span>
                </p>
                <van-progress
                  :percentage="item.indirectFans"
                  color="linear-gradient(to right, #464F66, #4C5773)"
                  stroke-width="10"
                />
                <p class="center-two-load">
                  <span>近30天缺货</span>
                  <span>3/10</span>
                </p>
                <van-progress
                  :percentage="item.directFans"
                  color="linear-gradient(to right, #464F66, #4C5773)"
                  stroke-width="10"
                />
                <p class="cneter-two-tress">
                  <span>分享优惠券/店铺/平台链接</span>
                  <span>3/500</span>
                </p>
                <van-progress
                  :percentage="item.selfPurchaseRebate"
                  color="linear-gradient(to right, #464F66, #4C5773)"
                  stroke-width="10"
                />
              </div>
              <div class="cneter-pic">
                <img src="../../static/会员升级_slices/编组 3.png" alt />
              </div>
              <div class="cneter-diamond" v-for="(item2,index2) in item.vipPrivileges" :key="index2">
                <p>
                  <img src="../../static/会员升级_slices (1)/会员.png" />
                </p>
                <p>{{item2}}</p>
              </div>
            </div>
            <div class="center-tress"></div>
          </div>
        </van-swipe-item>
        <!-- <van-swipe-item>
     <div class="Membership-center">
      <p class="center-one"></p>
     <div class="center-two-pell">
        <p class="center-two-first">
          <span>黄金会员</span>
          <span>未解锁</span>
        </p>
        <div class="cneter-general">
          <p class="center-two-last">
            <span>邀请新用户</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="center-two-load">
            <span>近30天缺货</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="cneter-two-tress">
            <span>分享优惠券/店铺/平台链接</span>
            <span>3/500</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
        </div>
        <div class="cneter-pic">
          <img src="../../static/会员升级_slices/编组 3.png" alt />
        </div>
        <div class="cneter-diamond" v-for="item in pics" :key="item">
          <p>
            <img :src="item.pic" />
          </p>
          <p>{{item.text}}</p>
        </div>
      </div>
       <div class="center-tress"></div>
     </div>
        </van-swipe-item>-->
        <!-- <van-swipe-item>
      <div class="Membership-center">
      <p class="center-one"></p> 
      <div class="center-two-peller">
        <p class="center-two-first">
          <span>白银会员</span>
          <span>未解锁</span>
        </p>
        <div class="cneter-general">
          <p class="center-two-last">
            <span>邀请新用户</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="center-two-load">
            <span>近30天缺货</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="cneter-two-tress">
            <span>分享优惠券/店铺/平台链接</span>
            <span>3/500</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
        </div>
        <div class="cneter-pic">
          <img src="../../static/会员升级_slices/编组 3.png" alt />
        </div>
        <div class="cneter-diamond" v-for="item in pics" :key="item">
          <p>
            <img :src="item.pic" />
          </p>
          <p>{{item.text}}</p>
        </div>
      </div>
       <div class="center-tress"></div>
      </div>
        </van-swipe-item>-->
      </van-swipe>
    </div>

    <!-- <div class="Membership-center"></div> -->
    <div class="Membership-foot"></div>
    <div class="Membership-footer">去邀请好友</div>
  </div>
</template>

<script>
import { getMember } from "@/api/Membership/Membership";
import { Toast } from "vant";
export default {
  name: "Membership",

  data() {
    return {
      id: "",
      index: "",
      getMemberlist: [],
      character: [
        {
          title: "专享福利",
          one: "隐藏福利券",
          two: "自购返利",
          tress: "直属粉丝购买奖励",
          fore: "间接粉丝购买奖励",
          pell: "团队奖励",
          poll: "分享赚钱",
          pall: "福利活动",
        },
        {
          title: "白银会员",
          one: "提升",
          two: "提升",
          tress: "提升",
          fore: "提升",
          pell: "提升",
          poll: "提升",
          pall: "提升",
        },
        {
          title: "黄金会员",
          one: "提升",
          two: "提升",
          tress: "提升",
          fore: "提升",
          pell: "提升",
          poll: "提升",
          pall: "提升",
        },
        {
          title: "钻石会员",
          one: "提升",
          two: "提升",
          tress: "提升",
          fore: "提升",
          pell: "提升",
          poll: "提升",
          pall: "提升",
        },
      ],
    };
  },

  mounted() {},
  created() {
    this.getMemberer();
  },
  methods: {
   
    onChange(index) {
      Toast("当前 Swipe 索引：" + index);
    },
    getMemberer() {
      let params = { id: this.id };
      getMember(params)
        .then((res) => {
          this.getMemberlist = res.data.data;

          console.log("11", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.Membership {
  // height: 1117px;
  // background-color: #222630;
  &-header {
    position: fixed;
    background-color: #222630;
    z-index: 99999;
    left: 0;
    right: 0;
    height: 60px;
    // border: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    .header-one {
      color: #cccccc;
      padding: 20px 0 0 11px;
    }
    .header-two {
      color: #cccccc;
      padding-top: 20px;
    }
    .header-tress {
      color: #cccccc;
      padding: 20px 11px 0 0;
      font-weight: bold;
    }
  }
  &-center {
    display: flex;
    padding-top: 60px;
    justify-content: space-between;
    top: 60px;
    left: 0;
    right: 0;
    // height: 372px;
    border: 1px solid #fff;
    .center-one {
      height: 372px;
      width: 16px;
      margin: 10px 12px 0 0;
      // border: 1px solid #fff;
      border-radius: 0 5px 5px 0;
      background-color: #b9a590;
    }
    .center-two {
      height: 372px;
      width: 100%;
      // width: 320px;
      margin-top: 10px;
      // border: 1px solid #fff;
      border-radius: 5px;
      background-color: #464f66;
      .center-two-first {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        :first-child {
          font-size: 18px;
          margin-left: 10px;
          color: #ffe5c8;
        }
        :last-child {
          font-size: 12px;
          height: 27px;
          width: 56px;
          color: #5d5d5d;
          background-color: #2c2c2d;
          line-height: 27px;
          text-align: center;
          border-radius: 100px 0px 0px 100px;
        }
      }
      .cneter-general {
        margin: 14px 10px 0 10px;
      }
      .center-two-last,
      .center-two-load,
      .cneter-two-tress {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ffe5c8;
      }
      .cneter-pic {
        text-align: center;
        margin-top: 20px;
      }
      .cneter-diamond {
        float: left;
        width: 25%;
        text-align: center;
        font-size: 10px;
        color: #ffcccccc;
      }
    }
    .center-two1 {
      height: 372px;
      width: 100%;
      // width: 320px;
      margin-top: 10px;
      // border: 1px solid #fff;
      border-radius: 5px;
      background-color: rgba(122, 96, 73, 1);
      .center-two-first {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        :first-child {
          font-size: 18px;
          margin-left: 10px;
          color: #ffe5c8;
        }
        :last-child {
          font-size: 12px;
          height: 27px;
          width: 56px;
          color: #fff;
          background-color: #CCCC;
          line-height: 27px;
          text-align: center;
          border-radius: 100px 0px 0px 100px;
        }
      }
      .cneter-general {
        margin: 14px 10px 0 10px;
      }
      .center-two-last,
      .center-two-load,
      .cneter-two-tress {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ffe5c8;
      }
      .cneter-pic {
        text-align: center;
        margin-top: 20px;
      }
      .cneter-diamond {
        float: left;
        width: 25%;
        text-align: center;
        font-size: 10px;
        color: #ffcccccc;
      }
    }
    .center-two2 {
      height: 372px;
      width: 100%;
      // width: 320px;
      margin-top: 10px;
      // border: 1px solid #fff;
      border-radius: 5px;
      background-color: rgba(95, 95, 95, 1);
      .center-two-first {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        :first-child {
          font-size: 18px;
          margin-left: 10px;
          color: #ffe5c8;
        }
        :last-child {
          font-size: 12px;
          height: 27px;
          width: 56px;
          color: #fff;
          background-color:#CCCC;
          line-height: 27px;
          text-align: center;
          border-radius: 100px 0px 0px 100px;
        }
      }
      .cneter-general {
        margin: 14px 10px 0 10px;
      }
      .center-two-last,
      .center-two-load,
      .cneter-two-tress {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ffe5c8;
      }
      .cneter-pic {
        text-align: center;
        margin-top: 20px;
      }
      .cneter-diamond {
        float: left;
        width: 25%;
        text-align: center;
        font-size: 10px;
        color: #ffcccccc;
      }
    }
    .center-tress {
      height: 372px;
      width: 16px;
      margin: 10px 0 0 12px;
      // border: 1px solid #fff;
      border-radius: 5px 0 0 5px;
      background-color: #a0a0a0;
    }
    //  .center-two-pell {
    //   height: 372px;
    //   padding-top: 10px;
    //   border-radius: 5px;
    //   background-color: rgba(122, 96, 73, 1);
    //   .center-two-first {
    //     display: flex;
    //     justify-content: space-between;

    //     :first-child {
    //       font-size: 18px;
    //       margin-left: 10px;
    //       color: #FFFFE5C8;
    //     }
    //     :last-child {
    //       font-size: 12px;
    //       height: 27px;
    //       width: 56px;
    //       color: #5d5d5d;
    //       background-color: #2c2c2d;
    //       line-height: 27px;
    //       text-align: center;
    //       border-radius: 100px 0px 0px 100px;
    //     }
    //   }
    //   .cneter-general {
    //     margin: 14px 10px 0 10px;
    //   }
    //   .center-two-last,
    //   .center-two-load,
    //   .cneter-two-tress {
    //     display: flex;
    //     justify-content: space-between;
    //     font-size: 12px;
    //     color: #ffe5c8;
    //   }
    //   .cneter-pic {
    //     text-align: center;
    //     margin-top: 20px;
    //   }
    //   .cneter-diamond {
    //     float: left;
    //     width: 25%;
    //     text-align: center;
    //     font-size: 10px;
    //     color: #ffcccccc;
    //   }
    // }
    //  .center-two-peller {
    //   height: 372px;
    //   padding-top: 10px;
    //   border-radius: 5px;
    //   background-color: rgba(95, 95, 95, 1);
    //   .center-two-first {
    //     display: flex;
    //     justify-content: space-between;

    //     :first-child {
    //       font-size: 18px;
    //       margin-left: 10px;
    //       color: #ffe5c8;
    //     }
    //     :last-child {
    //       font-size: 12px;
    //       height: 27px;
    //       width: 56px;
    //       color: #5d5d5d;
    //       background-color: #2c2c2d;
    //       line-height: 27px;
    //       text-align: center;
    //       border-radius: 100px 0px 0px 100px;
    //     }
    //   }
    //   .cneter-general {
    //     margin: 14px 10px 0 10px;
    //   }
    //   .center-two-last,
    //   .center-two-load,
    //   .cneter-two-tress {
    //     display: flex;
    //     justify-content: space-between;
    //     font-size: 12px;
    //     color: #ffe5c8;
    //   }
    //   .cneter-pic {
    //     text-align: center;
    //     margin-top: 20px;
    //   }
    //   .cneter-diamond {
    //     float: left;
    //     width: 25%;
    //     text-align: center;
    //     font-size: 10px;
    //     color: #ffcccccc;
    //   }
    // }
  }
  .Membership-foot {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    height: 500px;

    background: url("../../static/会员升级_slices/st.png") no-repeat center
      center;
    // .foot-ones {
    //   width: 25%;
    //   text-align: center;
    //   font-size: 14px;
    //   border: 1px solid #fff;
    //   height: 56px;
    //   background-color: #ffffe5c8;
    //     .foot-twos{
    //   font-size: 14px;
    //   padding-top:10px ;
    //   text-align: center;
    //   color: #FFB9A590;
    //   line-height: 30px;
    //   border: 1px solid #fff;
    // }
    // }
  }
  .Membership-footer {
    margin: 30px 16px 0 16px;
    text-align: center;
    line-height: 56px;
    border-radius: 50px;
    color: #fff;
    background: url("../../static/会员升级_slices/矩形.png") no-repeat center;
  }
}
</style>